@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        .marquee-parent {
            margin: 0 -5%;

            &:before,
            &:after {
                background: linear-gradient(to right, rgba(248, 251, 253, 1), rgba(248, 251, 253, 0));
                content: "";
                height: 100%;
                position: absolute;
                width: 10%;
                z-index: 2;
            }

            &:before {
                left: 0;
                top: 0;
            }

            &:after {
                right: 0;
                top: 0;
                transform: rotateZ(180deg);
            }

            div {
                display: flex;
                @include align-items(center);
            }
        }

        .card-img {
            width: 100%;
            max-width: 150px;
            @include trans;
        }

        .card {
            // width: calc(100%/5);

            a {

                &:hover,
                &:focus,
                &:active {
                    .card-img {
                        opacity: 0.5;
                    }
                }
            }

        }

        // @media (max-width: $breakpoint-lg) {
        //     .card {
        //         width: calc(100%/4);
        //     }
        // }

        // @media (max-width: $breakpoint-sm) {
        //     .card {
        //         width: calc(100%/2);
        //     }
        // }
    }
}