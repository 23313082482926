@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        .post-position {
            line-height: 100%;
            font-weight: 300;
        }

        .post-description {
            p {
                font-weight: 500;
            }
        }

        .swiper-custom-paginations {
            .swiper-pagination-bullet {
                background: $base-white;

                &.swiper-pagination-bullet-active {
                    background: $secondary-color;
                }
            }
        }

        .quote-icon {
            margin-bottom: -1rem;
            margin-left: -1.5rem;
            width: 45px;
            height: 45px;
            background: $primary-color;
            @include border-radius(100%);

            svg {
                width: 100%;
                height: 100%;
                padding: 0.7rem;
                opacity: 0.5;

                path {
                    fill: $base-black;
                }
            }
        }
    }
}