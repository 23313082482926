@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        .wrapper {
            .card {
                width: 100%;

                .card-img {
                    @include trans;
                }

                a {
                    @include trans;

                    &:hover,
                    &:focus,
                    &:active {
                        color: $primary-color;

                        .card-img {
                            @include transform(scale(1.1));
                        }
                    }
                }
            }
        }

        @media (min-width: $breakpoint-sm) {
            .wrapper {
                .card {
                    width: calc(100%/2);
                }
            }
        }

        @media (min-width: $breakpoint-md) {
            .wrapper {
                .card {
                    width: calc(100%/3);
                }
            }
        }

        @media (min-width: $breakpoint-lg) {
            .wrapper {
                .card {
                    width: calc(100%/5);
                }
            }
        }
    }
}