@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        // background: $primary-color;
        background: $base-white;
        color: $base-white;
        position: relative;
        // background-image: url("/site-assets/images/new-main-banner-overlay.jpg");
        // background-repeat: no-repeat;
        // background-size: cover;

        .icon-overlay {
            opacity: 0.4;
            top: 40%;
            left: -20%;
            width: 40%;
            @include transform(translateY(-40%));
        }

        .js-panel-border {
            border: none !important;
            @include box-shadow(1px, 1px, 25px, 1px, rgba($base-black, 0.2));
            border-radius: 50px;
        }

        .contentWrapper {
            padding: 10rem 0;
            min-height: 800px;
            z-index: 1;
            color: $font-color;
        }

        .imageWrapper {
            right: 0;
            top: 0;
            bottom: 0;
            width: 60%;

            .card-img {
                width: 100%;
                height: 100%;
                @include object-fit;
            }

            svg {
                height: 100%;
                width: auto;
            }
        }

        .mainImageWrapper {
            z-index: 1;
            padding: 11rem 8rem;

            .card-img {
                width: 100%;
                height: 100%;
                aspect-ratio: 1 / 1;
                @include object-fit;
            }
        }

        .js-container {
            margin: 3rem 0;

            .js-form-input-wrapper {
                background: $base-white;
                @include flex;
                @include border-radius(50px);
                padding: 0.5rem 0.5rem 0.5rem 2rem;
                border: 1px solid $base-white;

                .js-input-wrapper-query {
                    padding-right: 1rem;
                    width: 100%;
                    @include flex;
                    @include align-items(center);

                    input {
                        outline: none;
                        width: 100%;
                        background: transparent;
                        color: $font-color;
                        border: none;
                    }
                }

                .js-button {
                    white-space: nowrap;
                    @include primaryBtn;
                    margin: 0;
                    border: none;

                    &:after {
                        content: none;
                    }
                }
            }
        }

        .banner-anchor {
            z-index: 1;
            margin-left: -0.5rem;
            bottom: 50px;
            background: $secondary-color;
            padding: 0.6rem 0.875rem;
            cursor: pointer;
            @include trans;
            @include border-radius(50px);

            svg {
                width: 20px;
                height: 20px;

                path {
                    fill: $base-black;
                }
            }

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        @media (min-width:$breakpoint-lg) {
            .icon-overlay {
                bottom: 0;
            }
        }

        @media (max-width: $breakpoint-lg) {
            .contentWrapper {
                padding: 12rem 0 0 0;
                min-height: auto;
            }

            .mainImageWrapper {
                padding: 0 0 10rem 0;
            }
        }
    }

    &:global.no-image {
        .contentWrapper {
            padding: 10rem 0 5rem 0;
            min-height: 500px;
        }
    }

    &:global.featured-employer {
        .contentWrapper {
            min-height: 500px;
            padding: 10rem 0 5rem 0;
        }

        .mainImageWrapper {
            // padding: 0;
            padding: 10rem 0 0 0;
            @include flex;
            @include align-items(center);
            @include justify-content(center);

            .card-img {
                max-width: 250px;
                max-height: 250px;
                width: 100%;
                height: auto;
                @include object-fit(contain);
            }
        }

        @media (max-width: $breakpoint-md) {
            .contentWrapper {
                padding: 10rem 0 0rem 0;
                min-height: 400px;
            }

            .mainImageWrapper {
                padding: 0;
            }
        }
    }

    &:global:before {
        // content: "";
        // background: linear-gradient(90deg, #000 67.66%, rgba(0, 0, 0, 0.00) 100%);
        // position: absolute;
        // z-index: 1;
        // top: 0;
        // left: 0;
        // bottom: 0;
        // width: 60%;
    }

    &:global.hidden-image-1 {
        .contentWrapper {
            padding: 10rem 0 5rem 0;
            min-height: 500px;
        }
    }
}