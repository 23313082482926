@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        .swiper-slide {
            height: auto;
        }

        .swiperArrowWrapper {
            right: 0;
            top: 0;

            button {
                background: transparent;
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .card {
            // background: $primary-color;
            color: $base-black;
            border: 2px solid #ccc;
            padding: 5px 5px 0;
            border-radius: 20px;
            overflow: hidden;
            @include border-radius(25px);

            img {
                border-radius: 20px 20px 0 0 !important;
            }


            a {

                &:hover,
                &:focus,
                &:active {
                    color: $base-black;

                    .post-title {
                        color: $base-black;
                        opacity: 0.5;
                    }
                }
            }
        }

        .post-description {
            @include line-clamp(14px, 1.2, 4);
        }

        .post-title {
            @include line-clamp(18px, 1.2, 2);
            @include trans;
        }

        .post-tag {
            top: 15px;
            margin: 0 15px;
        }

    }
}