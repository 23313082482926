@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        color: $base-white;

        .card-img {
            width: 100%;
            height: 100%;
            opacity: 0.7;
            @include object-fit;
        }

        .imageWrapper {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;

            &:before {
                content: "";
                background: $base-black;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
            }
        }

        .fancyBox {
            z-index: 1;
            background: $base-black;
            padding: 1rem;
            @include border-radius(50px);
            @include center;
            cursor: pointer;

            svg {
                width: 30px;
                height: 25px;

                path {
                    fill: $base-white;
                }
            }
        }

        .contentWrapper {
            padding-bottom: 10rem;
        }


        @media (min-width: $breakpoint-sm) {
            .contentWrapper {
                width: 40%;

            }
        }

        @media (max-width: $breakpoint-sm) {
            .fancyBox {
                top: auto;
                bottom: 80px;
            }
        }

    }
}